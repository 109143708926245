import React, { useEffect } from 'react';

import Dashboard from '../components/Dashboard';


const Dashboard2 = () => {

  return (<>
    <Dashboard />
    
  </>
  );
};

export default Dashboard;